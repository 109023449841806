import React from "react"
import Footer from "../Modules/Footer"
import Navbar from "../Modules/Navbar"
import SupportedBy from "../Subpages/SupportedBy"
import Hero from "../Subpages/Hero"
import QuestSection from "../Modules/ProductFact/Quest"
import WarlordSection from "../Modules/ProductFact/Warlord"
import BoostsSection from "../Modules/ProductFact/Boosts"
import DullahanSection from "../Modules/ProductFact/Dullahan"
import LockPal from "../Modules/Resources/Lock"
import Communtiy from "../Modules/Resources/Community"
import News from "../Modules/Resources/News"
// import RibbonAnnouncement from "../Modules/Ribbon"

const HomePage: React.FC = () => {
  // const preset = window.location.hash === '#/paladin' ? "0" : "1";
  React.useEffect(() => {
    const navHighlighter = ()  => {
      let scrollY = window.scrollY;
      const sections = Array.from(document.getElementsByTagName("section"));

      sections.forEach((current : HTMLElement) => { 
        if (current.id === '') return;
        
        const sectionHeight = current.offsetHeight
        const sectionTop = current.offsetTop - 100;
        const sectionId = current.getAttribute("id");

        if(scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
          document.querySelector(".navbar a[href*=" + sectionId + "]")?.classList.add("active");
          if(scrollY > sectionTop + sectionHeight - 200) { 
            current.classList.add("active");
          }
        } else {
          if(sectionId === 'more' && scrollY > sectionTop + sectionHeight) {
            return;
          }
          current.classList.remove("active");
          document.querySelector(".navbar a[href*=" + sectionId + "]")?.classList.remove("active");
        }
      });
    }
    window.addEventListener("scroll", navHighlighter);

    return () => {
      window.removeEventListener("scroll", navHighlighter);
    }
  }, []);

  return (
    <>
    {/* <RibbonAnnouncement/> */}
      <Navbar/>
      <div className={'container'}>
        <Hero />
        <QuestSection />
        <WarlordSection />
        <DullahanSection />
        <BoostsSection />
        <SupportedBy />
        <section className='gradient-bottom-container' id='more'>
          <LockPal />
          {/* {
            isMobile ? null :
            (
              <ColorEllipseBottom />
            )
          } */}
          <Communtiy />
          <News />
        </section>
        </div>
      <Footer/>
    </>
  )
}

export default HomePage
