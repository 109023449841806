import React from "react";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import { TabPane, Tabs } from "@chainsafe/common-components";
import integrity from "./Images/integrity.png";
import transparency from "./Images/transparency.png";
import empowerment from "./Images/empowerment.png";
import inclusion from "./Images/inclusion.png";
import education from "./Images/education.png";

interface IProductSelector {
  value: string;
  onChangeTab: (value: string) => void;
}

const useStyles = makeStyles(
  ({ constants, palette, breakpoints, typography }: ITheme) => {
    return createStyles({
      container: {
        minHeight: "70vh",
        [breakpoints.up(1300)]: {
          height: "calc(100% - 180px)",
          paddingTop: "90px",
          paddingBottom: "90px",
          paddingLeft: "80px",
        },
        [breakpoints.up(1980)]: {
          height: "calc(100% - 360px)",
          paddingTop: "180px",
          paddingBottom: "180px",
          minHeight: "60vh",
        },
        [breakpoints.up(2560)]: {
          height: "calc(100% - 300px)",
          paddingTop: "150px",
          paddingBottom: "150px",
          minHeight: "25vh",
          justifyContent: "center",
        },
        [breakpoints.down(1300)]: {
          height: "calc(100% - 180px)",
          paddingTop: "90px",
          paddingBottom: "90px",
        },
        [breakpoints.down(1079)]: {
          height: "calc(100% - 140px)",
          paddingTop: "70px",
          paddingBottom: "70px",
        },
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      white: {
        color: palette.common.white.main,
      },
      orange: {
        color: palette.primary.main,
      },
      green: {
        color: "#55E56F",
      },
      ellipseFold: {
        position: "sticky",
        shapeOutside: "ellipse(800px 200px at 100% 100%)",
        width: "100%",
        height: "200px",
        clipPath: "ellipse(1500px 200px at 50% 0%)",
        bottom: "-235px",
        marginBottom: "32px",
      },
      gradient: {
        background:
          "linear-gradient(90deg, rgba(243, 39, 44, 0.6) 0%, rgba(246, 174, 64, 0.6) 100%)",
        filter: "blur(120px)",
        height: "260px",
        top: "60%",
        position: "absolute",
        left: "15%",
        width: "70%",
      },
      valueSelector: {
        paddingBottom: "2rem",
        position: "absolute",
        color: `${palette.common.white.main}`,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
        [breakpoints.down("xl")]: {
          left: "50%",
        },
        [breakpoints.down("lg")]: {
          left: "45%",
          top: "45%",
          transform: "translate(-40%, -40%)",
        },
        [breakpoints.down("md")]: {
          left: "25%",
          top: "25%",
          transform: "translate(-15%, -30%)",
        },
        [breakpoints.down(690)]: {
          left: "52%",
          top: "50%",
          transform: "translate(-52%, -52%)",
        },
        [breakpoints.down(590)]: {
          left: "43%",
          top: "25%",
          transform: "translate(-38%, -35%)",
        },
        [breakpoints.down(500)]: {
          left: "33%",
          top: "25%",
          transform: "translate(-23%, -35%)",
        },
        [breakpoints.down(420)]: {
          left: "20%",
          top: "25%",
          transform: "translate(-12%, -30%)",
        },
        [breakpoints.down(355)]: {
          left: "6%",
          top: "25%",
          transform: "translate(-3%, -30%)",
        },
      },
      valueTitle: {
        fontSize: "30px",
      },
      tabOverride: {
        "& > li:first-child": {
          marginLeft: "0",
        },
        "& > li:nth-child(4)": {
          [breakpoints.down(700)]: {
            marginLeft: "0",
          },
        },
        "& > li": {
          fontSize: "24px",
          marginRight: "0px",
          marginLeft: "64px",
          marginTop: "24px",
          "& > img": {
            opacity: ".4",
          },
        },
        "& > li.selected": {
          borderBottom: `1px solid #FF7543`,
          "& > img": {
            opacity: "1",
          },
        },
      },
      flex: {
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      },
    });
  }
);

const ValueSelector: React.FC<IProductSelector> = ({ value, onChangeTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.ellipseFold}>
      <div className={classes.gradient} />
      <div className={classes.valueSelector}>
        <div className={classes.flex}>
          <div className={classes.valueTitle}>Values</div>
          <Tabs
            className={classes.tabOverride}
            activeKey={value}
            onTabSelect={onChangeTab}
          >
            <TabPane
              title=""
              icon={<img src={integrity} alt="integrity" />}
              tabKey={"0"}
            >
              {" "}
            </TabPane>
            <TabPane
              title=""
              icon={<img src={transparency} alt="transparency" />}
              tabKey={"1"}
            >
              {" "}
            </TabPane>
            <TabPane
              title=""
              icon={<img src={empowerment} alt="empowerment" />}
              tabKey={"2"}
            >
              {" "}
            </TabPane>
            <TabPane
              title=""
              icon={<img src={inclusion} alt="inclusion" />}
              tabKey={"3"}
            >
              {" "}
            </TabPane>
            <TabPane
              title=""
              icon={<img src={education} alt="education" />}
              tabKey={"4"}
            >
              {" "}
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default ValueSelector;
