import React from "react";
import Navbar from "../Modules/Navbar";
import Footer from "../Modules/Footer";
import ValueSelector from "../Modules/ValueSelector";
import AboutHero from "../Subpages/AboutHero";
import { Typography } from "@chainsafe/common-components";

const info = [
  {
    title: "Integrity",
    text: "Scale sustainable governance and promote healthy dynamics across DeFi",
  },
  {
    title: "Transparency",
    text: "Provide easily accessible & open information to all stakeholders to enhance decentralized decision-making ",
  },
  {
    title: "Empowerment",
    text: "Make it easier to access effective governance for stronger ecosystems",
  },
  {
    title: "Inclusion",
    text: "Enable anyone to participate in governance and bring value to it",
  },
  {
    title: "Education",
    text: "Share best practices and wisdom for governing across communities",
  },
];

const AboutUs: React.FC = () => {
  const [value, setValue] = React.useState<string>("0");

  const onChangeTab = (value: string) => {
    setValue(value);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Navbar />
      <AboutHero />
      <ValueSelector value={value} onChangeTab={onChangeTab} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "550px",
        }}
      >
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            lineHeight: "28px",
            color: "#FFF",
            textAlign: "center",
            marginBottom: "16px",
          }}
        >
          {info[parseInt(value)].title}
        </Typography>
        <Typography
          variant="h1"
          style={{
            fontSize: "24px",
            lineHeight: "28px",
            color: "#FFF",
            textAlign: "center",
            margin: "0px 12px 64px 12px",
          }}
        >
          {info[parseInt(value)].text}
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
