import React from "react"
import { ThemeProvider, createTheme } from "@chainsafe/common-theme"
import { HashRouter } from "@chainsafe/common-components"
import "@chainsafe/common-theme/dist/font-faces.css"
import "./styles/app.scss"
import Routes from "./Components/Routes"

const theme = createTheme({
  globalStyling: {
    body: {
      backgroundColor: "#0F0F0F",
      overflowX: "hidden",
    },
    html:{
      scrollBehavior: "smooth",
    }

  },
  themeConfig: {
    palette: {
      primary: {
        main: "#FF7544",
      },
      secondary: {
        main: "#112A45",
        light: "#61C7FF"
      }
    },
    constants: {
      headerHeight: 70,
    },
    typography: {
      h1: {
        fontSize: "72px",
        lineHeight: "80px",
      },
      h2: {
        fontSize: "48px",
        lineHeight: "56px",
      },
      h3: {
        fontSize: "30px",
        lineHeight: "38px",
      },
      h4: {
        fontSize: "24px",
        lineHeight: "28px"
      }
    }
  }
})

const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
        <HashRouter>
          <Routes />
        </HashRouter>
    </ThemeProvider>
  )
}

export default App
