import React from "react";
import { createStyles, ITheme, makeStyles } from "@chainsafe/common-theme";
import { Typography } from "@chainsafe/common-components";
import clsx from "clsx";
import palLogo from "./Images/palLogo.png";

const useStyles = makeStyles(
  ({ constants, palette, breakpoints, typography }: ITheme) => {
    return createStyles({
      container: {
        marginTop: "40px",
        minHeight: "40vh",
        [breakpoints.up(1300)]: {
          height: "calc(100% - 180px)",
          paddingTop: "90px",
          paddingLeft: "80px",
        },
        [breakpoints.up(1980)]: {
          height: "calc(100% - 360px)",
          paddingTop: "180px",
          minHeight: "60vh",
        },
        [breakpoints.up(2560)]: {
          height: "calc(100% - 300px)",
          paddingTop: "150px",
          minHeight: "25vh",
          justifyContent: "center",
        },
        [breakpoints.down(1300)]: {
          height: "calc(100% - 180px)",
          paddingTop: "90px",
        },
        [breakpoints.down(1079)]: {
          height: "calc(100% - 140px)",
          paddingTop: "70px",
          paddingBottom: "70px",
        },
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      },
      contentContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",

        justifyContent: "center",
        alignItems: "center",
        margin: "1rem",

        [breakpoints.up("md")]: {
          margin: "1rem .1rem",
        },
      },
      imgContentContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        [breakpoints.up("sm")]: {
          maxWidth: "90%",
        },
        [breakpoints.up("md")]: {
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "75%",
        },
      },
      heroContentContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "70px 0",
        maxWidth: "75%",
        width: "100%",
        alignItems: "center",
        [breakpoints.down("md")]: {
          flexDirection: "column",
          maxWidth: "100%",
          margin: "48px 0 28px 0",
        },
      },
      semibold: {
        fontWeight: 800,
      },
      bold: {
        fontWeight: "bolder",
      },
      white: {
        color: palette.common.white.main,
      },
      singleColText: {
        textAlign: "left",
        maxWidth: 600,
        [breakpoints.up(2560)]: {
          textAlign: "center",
        },
      },
      orange: {
        color: palette.primary.main,
      },
      green: {
        color: "#55E56F",
      },
      titleText: {
        textAlign: "left",
        marginTop: "0",
        marginLeft: "3%",
        width: "100%",
        color: palette.common.white.main,
        fontSize: "32px",
        lineHeight: "38px",
        [breakpoints.down("md")]: {
          fontSize: "32px",
          lineHeight: "38px",
          marginLeft: "0",
          marginTop: "5%",
        },
      },
      textGroup: {
        display: "flex",
        width: "100%",
        borderRadius: "12px",
        padding: "40px",
        flexDirection: "column",
        background: "#1F1F1F",
        maxWidth: "350px",
      },
      spacingBottom: {
        [breakpoints.down("md")]: {
          marginBottom: "40px",
        },
      },
      noUnderline: {
        textDecoration: "none",
      },
      textUpper: {
        textAlign: "left",
        fontSize: "30px",
        fontWeight: 600,
        lineHeight: "21px",
        marginBottom: "16px",
      },
      textLower: {
        textAlign: "left",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "24px",
      },
      hide: {
        [breakpoints.down(1300)]: {
          display: "none",
        },
      },
    });
  }
);

const AboutHero: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <section className={classes.contentContainer}>
        <div className={classes.imgContentContainer}>
          <img src={palLogo} alt="Paladin Logo" style={{ width: "auto" }} />
          <Typography
            variant="h1"
            className={clsx(classes.bold, classes.titleText)}
          >
            From a hackathon to DAO launching 3 DeFi dapps in a year, we're here
            to bring value to governance
          </Typography>
        </div>
        <div className={classes.heroContentContainer}>
          <div className={clsx(classes.textGroup, classes.spacingBottom)}>
            <Typography
              variant="h1"
              className={clsx(classes.textUpper, classes.white)}
            >
              Mission
            </Typography>
            <Typography
              variant="h1"
              className={clsx(classes.textLower, classes.white)}
            >
              We are committed to offering a range of solutions that anyone can
              utilize to bring value to governance across DeFi
            </Typography>
          </div>
          <div className={classes.textGroup}>
            <Typography
              variant="h1"
              className={clsx(classes.textUpper, classes.white)}
            >
              Vision
            </Typography>
            <Typography
              variant="h1"
              className={clsx(classes.textLower, classes.white)}
            >
              Create a world where being an active governance contributor is
              worthwhile while promoting sustainable dynamics in the ecosystem
            </Typography>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutHero;
