import React from 'react';
import { createStyles, ITheme, makeStyles } from '@chainsafe/common-theme';
import ballot from './Images/ballot.gif';

const useStyles = makeStyles(({ constants, palette, breakpoints, typography }: ITheme) => {
  return createStyles({
    container: {
      minHeight: '70vh',
      [breakpoints.up(1300)]: {
        height: 'calc(100% - 180px)'
      },
      [breakpoints.up(1980)]: {
        height: 'calc(100% - 360px)',
        paddingTop: '180px',
        minHeight: '60vh',
      },
      [breakpoints.up(2560)]: {
        height: 'calc(100% - 300px)',
        paddingTop: '150px',
        minHeight: '25vh',
        justifyContent: 'center',
      },
      [breakpoints.down(1300)]: {
        height: 'calc(100% - 180px)',
        paddingTop: '90px',
      },
      [breakpoints.down(1079)]: {
        height: 'calc(100% - 140px)',
        paddingTop: '70px',
        paddingBottom: '70px',
      },
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    contentContainer: {
      marginLeft: 'auto',
      marginRight: 'auto',
      [breakpoints.up('md')]: {
        display: 'grid',
        gridTemplateColumns: '1fr .6fr',
        gridGap: '0px',
      },
      [breakpoints.down(500)]: {
        width: '98%',
      },
    },
    imgContentContainer: {
      [breakpoints.up('md')]: {
        display: 'flex',
        justifyContent: 'flex-start',
        position: 'relative',
      },
      [breakpoints.down('md')]: {
        display: 'flex',
        justifyContent: 'center',
        '& > img': {
          width: '95%',
        },
      },
      '& > img': {
        maxWidth: '500px',
      },
    },
    heroContentContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      [breakpoints.down('md')]: {
        padding: '3rem 1rem',
      },
      [breakpoints.down(500)]: {
        padding: '3rem .5rem',
      },
      [breakpoints.up(2560)]: {
        alignItems: 'center',
      },
    },
    semibold: {
      fontWeight: 800,
    },
    bold: {
      fontWeight: 'bolder',
    },
    reduceLeading: {
      letterSpacing: '-1.25px',
    },
    white: {
      color: palette.common.white.main,
    },
    grey:{
      color: "#A3A3A3" ,
    },
    singleColText: {
      fontFamily: 'DM Sans',
      textAlign: 'left',
      maxWidth: 600,
      [breakpoints.down(500)]: {
        width: '100%',
        fontSize: '20px',
      },
      [breakpoints.up(2560)]: {
        textAlign: 'center',
      },
    },
    orange: {
      color: palette.primary.main,
    },
    green: {
      color: '#55E56F',
    },
    titleText: {
      fontFamily: 'Public Sans',
      textAlign: 'left',
      maxWidth: '800px',
      color: palette.common.white.main,
      fontSize: '60px',
      lineHeight: '72px',
      [breakpoints.down(500)]: {
        fontSize: '48px',
        lineHeight: '63px',
      },
      [breakpoints.up(2560)]: {
        textAlign: 'center',
      },
    },
    buttonGroup: {
      display: 'flex',
      width: '100%',
      paddingTop: '12px',
      [breakpoints.down(700)]: {
        flexDirection: 'column',
        width: '50%',
      },
      [breakpoints.down(500)]: {
        flexDirection: 'column',
        width: '70%',
      },
    },
    noUnderline: {
      textDecoration: 'none',
    },
    buttonBasic: {
      background: 'transparent',
      borderRadius: '12px',
      fontSize: '18px',
      lineHeight: '26px',
      textAlign: 'center',
      padding: constants.generalUnit,
      transition: 'all ease-in-out 0.2s',
      '&:hover, &:active, &:focus': {
        border: `1px solid ${palette.common.white.main}`,
        background: 'transparent',
      },
    },
    buttonLearnMore: {
      border: `1px solid #D46B08`,
      background: "#D46B08",
      borderRadius: '8px',
      padding: "12px 0 12px 0",
      textAlign: 'center',
      width: "128px",
      [breakpoints.down(768)]: {
        marginRight: '0px',
        marginTop: '5%',
        fontSize: '24px',
        width: '100%',
      },
    },
    buttonText: {
      fontSize: '16px',
      fontFamily: "DM Sans",
    },
    buttonWarden: {
      border: `1px solid #55E56F`,
      [breakpoints.down(700)]: {
        marginTop: '15%',
        fontSize: '24px',
      },
    },
  });
});

const Hero: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.container + ' main'}>
      <section className={classes.contentContainer + ' main-section'}>
        <div className={classes.heroContentContainer + ' content'}>
          <h1 className='home-title'>Empower tokenholders with vote markets</h1>
          <p className='subtitle'>
            Paladin unlocks DeFi value through governance power and marketplaces
          </p>
          <br />
          <a
            className='primary-pal button'
            href='https://doc.paladin.vote/'
            rel='noopener noreferrer'
            target='_blank'
            style={{ textDecoration: 'none' }}
          >
            Learn More
          </a>
        </div>
        <div className={classes.imgContentContainer}>
          <img src={ballot} alt='Ballots going into ballot box' style={{ objectFit: 'contain' }} />
        </div>
      </section>
    </div>
  );
};

export default Hero;
