import React from "react";
import {
  ITheme,
  makeStyles,
  createStyles,
} from "@chainsafe/common-theme";
import palGraphic from "../Images/hpal-graphic.png";

const useStyles = makeStyles(
  ({ constants, palette, breakpoints, typography }: ITheme) => {
    return createStyles({
      container: {
        overflowX: "hidden",
        objectFit: "cover",
        objectPosition: "top",
        minHeight: "30vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        [breakpoints.up(2560)]: {
          maxWidth: "1350px",
          minHeight: "30vh",
          position: "relative",
          left: "50%",
          transform: "translate(-50%)",
        },
        [breakpoints.down(767)]: {
          alignItems: "flex-start",
        },
      },
      bold: {
        fontWeight: "bolder",
      },
      noUnderline: {
        textDecoration: "none",
      },
      white: {
        color: palette.common.white.main,
      },
      orange: {
        color: palette.primary.main,
      },
      factSection: {
        background: "#141414",
        borderRadius: "20px",
        border: "1px solid #292929",
        width: "80%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "3%",
        paddingRight: "3%",
        paddingTop: "60px",
        paddingBottom: "60px",
        margin: "8% auto 0 auto",
        maxWidth: "1020px",
        [breakpoints.down(1250)]: {
          width: "90%",
        },
        [breakpoints.down(800)]: {
          flexDirection: "column-reverse",
          gap: "65px",
          alignItems: "center",
        },
        [breakpoints.down(768)]: {
          paddingLeft: "1%",
          paddingRight: "1%",
        },
        [breakpoints.down(400)]: {
          paddingLeft: "3%",
          paddingRight: "3%",
        },
      },
      aboutBox: {
        width: "57%",
        display: "flex",
        flexDirection: "column",
        [breakpoints.down(1170)]: {
        },
        [breakpoints.down(850)]: {
          width: "85%",
          marginBottom: "50px",
        },
        [breakpoints.down(400)]: {
          width: "98%",
        },
      },
      productLogo: {
        maxWidth: "400px",
        maxHeight: "400px",
        width: "30%",
        height: "30%",
        [breakpoints.down(1250)]: {
          width: "320px",
          height: "320px",
        },
        [breakpoints.down(1100)]: {
          width: "38%",
          height: "38%",
        },
        [breakpoints.down(769)]: {
          width: "40%",
          height: "40%",
        },
        [breakpoints.down(426)]: {
          width: "60%",
          height: "60%",
        },
      },
      productTitle: {
        display: "block",
        fontFamily: "Public Sans, sans-serif",
        textAlign: "left",
        fontSize: "48px",
        lineHeight: "32px",
        color: "#D46B08",
        fontWeight: 800,
        letterSpacing: "-1px",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      aboutDescription: {
        display: "block",
        fontFamily: "DM Sans, sans-serif",
        textAlign: "left",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#A3A3A3",
        fontWeight: 300,
        fontStyle: "normal",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      buttonGroup: {
        display: "flex",
        width: "100%",
        paddingTop: "16px",
        [breakpoints.down(768)]: {
          flexDirection: "column",
          marginTop: "25px",
        },
      },
      buttonLaunch: {
        marginRight: "32px",
        [breakpoints.down(768)]: {
          marginRight: "0px",
          marginBottom: "30px",
        },
      },
      buttonBasic: {
        fontFamily: "DM Sans, sans-serif",
        background: "#292929",
        border: `1px solid #292929`,
        fontSize: "16px",
        fontWeight: "bold",
        borderRadius: "20px",
        lineHeight: "26px",
        padding: "12px 60px !important",
        textAlign: 'center',
        transition: "all ease-in-out 0.2s",
        color: "#D46B08",
        [breakpoints.down(960)]: {
          padding: "12px 40px !important",
        },
        "&:hover, &:active, &:focus": {
          border: `1px solid ${palette.common.white.main}`,
        },
        "& > a": {
          padding: "0% 100%",
        },
      },
    });
  }
);

const LockPal: React.FC = () => {
  const classes = useStyles();

  return (
    <section id="more" className={classes.factSection}>
      <div className={classes.aboutBox}>
        <div>
          <h2 className='secondary-pal'>Lock $PAL</h2>
          <br />
          <br />
          <p className='subtitle-secondary'>
            Govern the Paladin protocol with Holy PAL (hPAL),
            a staked or locked version of PAL. hPAL will be
            the heart of a vote market flywheel.
          </p>
          <br />
          <div className={classes.buttonGroup + ' align-center button-group'}>
            <a
              className='button-pal-secondary'
              href="https://hpal.paladin.vote/"
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              hPal
            </a>
            <a
              className='button-pal-secondary'
              href="https://doc.paladin.vote/governance/holy-pal-hpal"
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
      <img
        src={palGraphic}
        className={classes.productLogo}
        alt="Vote Incentives"
      />
    </section>
  );
};

export default LockPal;
