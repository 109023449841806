import React from "react";
import { ITheme, makeStyles, createStyles } from "@chainsafe/common-theme";
import { Typography } from "@chainsafe/common-components";
import clsx from "clsx";
import gf from "./Images/supportors/GF1.png";
import galaxy from "./Images/supportors/galaxy_digital.png";
import nfx from "./Images/supportors/NFX.png";
import semantic from "./Images/supportors/semantic.png";

const useStyles = makeStyles(
  ({ constants, palette, breakpoints, typography }: ITheme) => {
    return createStyles({
      container: {
        background: "#141414",
        borderRadius: "20px",
        border: "1px solid #292929",
        width: "80%",
        marginTop: "8%",
        padding: "2% 5%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "8%",
        textAlign: "center",
        maxWidth: "1020px",
        [breakpoints.down(1250)]: {
          width: "90%",
        },
        [breakpoints.down(769)]: {
          padding: "6% 0%",
        },
        [breakpoints.down(400)]: {
          padding: "6% 6%",
        },
      },
      bold: {
        fontWeight: 800,
      },
      reduceLeading: {
        letterSpacing: "-1px",
      },
      white: {
        color: palette.common.white.main,
      },
      titleText: {
        fontFamily: 'Public Sans',
        textAlign: "left",
        marginBottom: constants.generalUnit * 4,
        fontSize: "48px",
        [breakpoints.down("769")]: {
          fontSize: "36px",
          lineHeight: "55px",
          maxWidth: "100%",
          textAlign: "center",
          marginBottom: constants.generalUnit * 2,
        }
      },
      marginTop: {
        marginTop: "1.25rem",
        [breakpoints.down(767)]: {
          marginTop: "1rem",
        },
      },
      factsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: "65px",
        [breakpoints.down('769')]: {
          marginTop: "25px",
          flexWrap: 'wrap',
          justifyContent: "center",
          gap: '25px',
        },
      },
      supporters: {
        [breakpoints.down('950')]:{
          marginTop: "35px",
          '& img': {
            width: "70%",
          }
        },
        [breakpoints.down('769')]:{
          marginTop: "35px",
          '& img': {
            width: 'unset',
          },
          "&:nth-child(3)": {
            width: "100%",
          },
          "&:nth-child(4)": {
            width: "100%",
          },
        },
      },
      noUnderline: {
        textDecoration: "none",
      },
    })
  })

const SupportedBy: React.FC = () => {
  const classes = useStyles();

  return (
    <div className='supported-container'>
    <section className={classes.container + ' supported-section'}>
      <Typography variant="h1" className={clsx(classes.white, classes.bold, classes.reduceLeading, classes.titleText)}>
        Proudly supported by
      </Typography>
      {/* borrower view */}
        <div className={clsx(classes.marginTop, classes.factsContainer)}>
          <div className={clsx(classes.supporters)}>
            <img src={gf} alt="Green Field One logo"/>
          </div>
          <div className={clsx(classes.supporters)}>
            <img src={nfx} alt="Nfx logo"/>
          </div>
          <div className={clsx(classes.supporters)}>
            <img src={semantic} alt="Semantic Ventures"/>
          </div>
          <div className={clsx(classes.supporters)}>
            <img src={galaxy} alt="Galaxy Digital Logo"/>
          </div>
        </div>
    </section >
    </div>
  )
}

export default SupportedBy;