import React from "react";
import {
  ITheme,
  makeStyles,
  createStyles,
} from "@chainsafe/common-theme";
import { Typography } from "@chainsafe/common-components";
// import dullahanMobile from "../Images/dullahan-mobile.png";

const useStyles = makeStyles(
  ({ constants, palette, breakpoints, typography }: ITheme) => {
    return createStyles({
      container: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: "25vmax",
        [breakpoints.down(769)]: {
          marginBottom: "65vmin",
        },
        [breakpoints.up(1500)]: {
          marginBottom: "25vmax",
        },
        [breakpoints.up(1800)]: {
          marginBottom: "18vmax",
        },
        [breakpoints.up(2600)]: {
          marginBottom: "15vmax",
        },
      },
      bold: {
        fontWeight: "bolder",
      },
      noUnderline: {
        textDecoration: "none",
      },
      white: {
        color: palette.common.white.main,
      },
      orange: {
        color: palette.primary.main,
      },
      factSection: {
        position: "relative",
        top: "18vmax",
        background: "#141414",
        borderRadius: "20px",
        border: "1px solid #292929",
        width: "70%",
        paddingLeft: "3%",
        paddingRight: "3%",
        paddingTop: "60px",
        paddingBottom: "60px",
        margin: "0 auto 0 auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        maxWidth: "800px",
        [breakpoints.up(1500)]: {
          top: "14vmax",
        },
        [breakpoints.up(1800)]: {
          top: "11vmax",
        },
        [breakpoints.up(2200)]: {
          top: "9vmax",
        },
        [breakpoints.up(2600)]: {
          top: "7vmax",
        },
        [breakpoints.down(1250)]: {
          width: "90%",
        },
        [breakpoints.down(1000)]: {
          top: "26vmin",
        },
        [breakpoints.down(769)]: {
          paddingLeft: "1%",
          paddingRight: "1%",
          top: "53vmin",
        },
        [breakpoints.down(400)]: {
          paddingLeft: "3%",
          paddingRight: "3%",
        },
      },
      aboutBox: {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        [breakpoints.down(850)]: {
          width: "85%",
          marginBottom: "50px",
        },
        [breakpoints.down(400)]: {
          width: "98%",
        },
      },
      productLogo: {
        position: "absolute",
        width: "90%",
        maxWidth: "800px",
        [breakpoints.down(1250)]: {
          width: "100%",
        },
      },
      productTitle: {
        display: "block",
        fontFamily: "Public Sans, sans-serif",
        textAlign: "left",
        fontSize: "48px",
        lineHeight: "32px",
        color: "#fff",
        fontWeight: 800,
        letterSpacing: "-1px",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      aboutTitle: {
        display: "block",
        fontFamily: "Public Sans, sans-serif",
        textAlign: "left",
        fontSize: "24px",
        lineHeight: "32px",
        color: "#fff",
        fontWeight: 400,
        fontStyle: "normal",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      aboutDescription: {
        display: "block",
        fontFamily: "DM Sans, sans-serif",
        textAlign: "left",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#8C8C8C",
        fontWeight: 400,
        fontStyle: "normal",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      buttonGroup: {
        display: "flex",
        width: "100%",
        paddingTop: "16px",
        [breakpoints.down(768)]: {
          flexDirection: "column",
          marginTop: "25px",
        },
      },
      buttonLaunch: {
        background: "#6938EF",
        border: `1px solid #6938EF`,
        marginRight: "32px",
        [breakpoints.down(768)]: {
          marginRight: "0px",
          marginBottom: "30px",
        },
      },
      buttonLearn: {
        border: `1px solid #292929`,
      },
      buttonBasic: {
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        borderRadius: "8px",
        lineHeight: "26px",
        padding: "12px 60px !important",
        textAlign: 'center',
        transition: "all ease-in-out 0.2s",
        [breakpoints.down(960)]: {
          padding: "12px 40px !important",
        },
        "&:hover, &:active, &:focus": {
          border: `1px solid ${palette.common.white.main}`,
        },
        "& > a": {
          padding: "0% 100%",
        },
      },
    });
  }
);

const DullahanSection: React.FC = () => {
  const classes = useStyles();

  return (
    <div className='sub-section dullahan'>
      <section id="dullahan" className={classes.container + ' dullahan-section'} >
        {/* {
          isMobile ?
          (
            <img
              src={dullahanMobile}
              className={classes.productLogo}
              alt="Dullahan Logos"
            />
          )
          :
          (
            <img
              src={dullahanGraphic}
              className={classes.productLogo + ' fixed-img'}
              alt="Dullahan Logos"
            />
          )
        } */}
        <div className={classes.factSection + ' box'}>
          <div className={classes.aboutBox}>
            <div>
              <h2>Dullahan</h2>
              <br />
              <br />
              <Typography className={classes.aboutTitle}>
                Borrow the cheapest GHO. Compound your AAVE.
              </Typography>
              <br />
              <p className='subtitle-secondary'>
                Auto-compound your stkAAVE rewards while also earning fees from GHO borrowers. 
                Borrowers can leverage lower interest rates from stkAAVE’s discount power, 
                managed seamlessly through Dullahan. 
              </p>
              <br />
              <div className={classes.buttonGroup + ' align-center button-group'}>
                <a
                  className='button-dullahan primary-dullahan'
                    href="https://dullahan.paladin.vote/"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{textDecoration: 'none'}}
                    >
                  Launch App
                </a>
                <a
                  className='button-secondary'
                    href="https://doc.paladin.vote/dullahan/overview"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{textDecoration:'none'}}
                    >
                    Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DullahanSection;
