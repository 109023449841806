import React from "react";
import {
  ITheme,
  makeStyles,
  createStyles,
} from "@chainsafe/common-theme";
import { Typography } from "@chainsafe/common-components";
import questGraphic from "../Images/quest-graphic.png";

const useStyles = makeStyles(
  ({ constants, palette, breakpoints, typography }: ITheme) => {
    return createStyles({
      container: {
        overflowX: "hidden",
        objectFit: "cover",
        objectPosition: "top",
        minHeight: "30vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        [breakpoints.up(2560)]: {
          maxWidth: "1350px",
          minHeight: "30vh",
          position: "relative",
          left: "50%",
          transform: "translate(-50%)",
        },
        [breakpoints.down(767)]: {
          alignItems: "flex-start",
        },
      },
      bold: {
        fontWeight: "bolder",
      },
      noUnderline: {
        textDecoration: "none",
      },
      white: {
        color: palette.common.white.main,
      },
      orange: {
        color: palette.primary.main,
      },
      factSection: {
        background: "#141414",
        borderRadius: "20px",
        border: "1px solid #292929",
        width: "80%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "3%",
        paddingRight: "3%",
        paddingTop: "60px",
        paddingBottom: "60px",
        margin: "8% auto 0 auto",
        maxWidth: "1020px",
        [breakpoints.down(1250)]: {
          width: "90%",
        },
        [breakpoints.down(800)]: {
          flexDirection: "column",
          alignItems: "center",
        },
        [breakpoints.down(768)]: {
          paddingLeft: "1%",
          paddingRight: "1%",
        },
        [breakpoints.down(400)]: {
          paddingLeft: "3%",
          paddingRight: "3%",
        },
      },
      aboutBox: {
        width: "57%",
        display: "flex",
        flexDirection: "column",
        [breakpoints.down(1170)]: {
        },
        [breakpoints.down(850)]: {
          width: "85%",
          marginBottom: "50px",
        },
        [breakpoints.down(400)]: {
          width: "98%",
        },
      },
      productLogo: {
        maxWidth: "350px",
        maxHeight: "350px",
        [breakpoints.down(1250)]: {
          width: "330px",
          height: "330px",
        },
        [breakpoints.down(1100)]: {
          width: "38%",
          height: "38%",
        },
        [breakpoints.down(768)]: {
          width: "80%",
          height: "80%",
        },
      },
      productTitle: {
        display: "block",
        fontFamily: "Public Sans, sans-serif",
        textAlign: "left",
        fontSize: "48px",
        lineHeight: "32px",
        color: "#fff",
        fontWeight: 800,
        letterSpacing: "-1px",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      aboutTitle: {
        display: "block",
        fontFamily: "Public Sans, sans-serif",
        textAlign: "left",
        fontSize: "22px",
        lineHeight: "32px",
        color: "#fff",
        fontWeight: 400,
        fontStyle: "normal",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      aboutDescription: {
        display: "block",
        fontFamily: "DM Sans, sans-serif",
        textAlign: "left",
        fontSize: "20px",
        lineHeight: "30px",
        color: "#8C8C8C",
        fontWeight: 400,
        fontStyle: "normal",
        [breakpoints.down(768)]: {
          display: "unset",
          marginRight: "6px",
        },
      },
      buttonGroup: {
        display: "flex",
        width: "100%",
        paddingTop: "16px",
        [breakpoints.down(768)]: {
          flexDirection: "column",
          marginTop: "25px",
        },
      },
      buttonLaunch: {
        background: "#68B463",
        border: `1px solid #68B463`,
        marginRight: "32px",
        [breakpoints.down(768)]: {
          marginRight: "0px",
          marginBottom: "30px",
        },
      },
      buttonLearn: {
        border: `1px solid #292929`,
      },
      buttonBasic: {
        fontFamily: "DM Sans, sans-serif",
        fontSize: "16px",
        fontWeight: "bold",
        borderRadius: "8px",
        lineHeight: "26px",
        padding: "12px 60px !important",
        textAlign: 'center',
        transition: "all ease-in-out 0.2s",
        [breakpoints.down(960)]: {
          padding: "12px 40px !important",
        },
        "&:hover, &:active, &:focus": {
          border: `1px solid ${palette.common.white.main}`,
        },
        "& > a": {
          padding: "0% 100%",
        },
      },
    });
  }
);

const QuestSection: React.FC = () => {
  const classes = useStyles();

  return (
    <div className='sub-container'>
      <section id="quest" className={classes.factSection + ' quest-section'}>
        <div className={classes.aboutBox}>
          <div>
            <h2>Quest</h2>
            <br />
            <br />
            <Typography className={classes.aboutTitle}>
              Boost liquidity. Earn more from your vote.
            </Typography>
            <br />
            <p className='subtitle-secondary'>
              Experience certainty with fixed incentives for gauge votes. Compatible with Curve, Balancer,
              and Bunni ecosystems. CVX, AURA, and LIQ voters can also delegate to Paladin to maximize
              rewards across all incentive platforms.
            </p>
            <br />
            <div className={classes.buttonGroup + ' align-center button-group'}>
              <a
                className='button-quest primary-quest'
                href="https://quest.paladin.vote/"
                rel="noopener noreferrer"
                target="_blank"
                style={{textDecoration: 'none'}}
              >
                Launch App
              </a>
              <a
                className='button-secondary'
                  href="https://doc.paladin.vote/quest-v2/overview"
                  rel="noopener noreferrer"
                  target="_blank"
                  style={{textDecoration:'none'}}
                >
                  Learn More
              </a>
            </div>
          </div>
        </div>
        <img
          src={questGraphic}
          className={classes.productLogo}
          alt="Vote Incentives"
        />
      </section>
    </div>
  );
};

export default QuestSection;
